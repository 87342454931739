import React, {useEffect} from 'react'
import './App.css';
import Header from './Header.js'
import Home from './Home.js'
import Checkout from './Checkout.js'
import Payment from './Payment.js'
import Login from './Login.js'
import Orders from './Orders.js'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {auth} from './firebase'
import {useStateValue} from './StateProvider'
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

const promise = loadStripe('pk_test_51IR9aJDvumY4QTIPDHDlPChtCx0dCEnpE9VDVvXjVDNz6Mt8g8aWyg301ktsbEyhT4JEp4VBRFxyk1EjEhYQlcLU00PqywyU8H')

function App() {

  const [{},dispatch] = useStateValue()

  useEffect(()=>{
        auth.onAuthStateChanged(
            authUser=>{
                console.log('User is >>>>', authUser)

                if(authUser){
                    dispatch({
                     type:'SET_USER',
                     user:authUser   
                    })
                }else{
                    dispatch({
                     type:'SET_USER',
                     user:null   
                    })
                }
            })
        },[])
  return (
    //BEM
    <Router>
        <div className="app">
         <Switch>
            <Route path="/login">
                <Login/>
            </Route>
            <Route path="/orders">
                <Header/>
                <Orders/>
            </Route>
            <Route path="/checkout">
                <Header/>
                <Checkout/>
            </Route>
            <Route path="/payment">
                <Header/>
                <Elements stripe={promise}>
                    <Payment/>
                </Elements>
            </Route>
            <Route path="/">
                <Header/>
                <Home/>
            </Route>
         </Switch>
        </div>
    </Router>
  );
}

export default App;
