import React from 'react'
import './Product.css'
import { useStateValue } from './StateProvider'


function Product({ id, title, image, price, rating }) {
    const [{ basket }, dispatch] = useStateValue()
    const addToBasket = () => {
        dispatch({
            type: "ADD_TO_BASKET",
            item: {
                id: id,
                title: title,
                image: image,
                price: price,
                rating: rating
            }
        })
    }
    return (
        <div className='product'>
            <p className='product__info'>{title}</p>
            <p className='product__price'>
                <small>$</small>
                <strong>{price}</strong>
            </p>
            <div className='product__rating'>
                {Array(rating).fill().map((_, x) => (
                    <p> * </p>
                ))
                }
            </div>
            <img className='home__image'
                src={image}
                alt=""
            />
            <button onClick={addToBasket} >Add to Basket</button>
        </div>
    )
}

export default Product