import firebase from 'firebase'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDzlnInM4RFRnLacPlqcFefdAKzicjFoxY",
    authDomain: "challenge-f7a16.firebaseapp.com",
    projectId: "challenge-f7a16",
    storageBucket: "challenge-f7a16.appspot.com",
    messagingSenderId: "1066124790699",
    appId: "1:1066124790699:web:c8cfdafcc40e3910aae0ee",
    measurementId: "G-2GXYRBQ0QJ"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)

const db = firebaseApp.firestore()
const auth = firebase.auth()

export {db, auth}