import React from 'react'
import './Home.css'
import Product from './Product.js'
function Home() {
    return (
        <div className='home'>
            <div className='home__container'>
                <img
                    className="home__image"
                    src="https://images-eu.ssl-images-amazon.com/images/G/02/digital/video/merch2016/Hero/Covid19/Generic/GWBleedingHero_ENG_COVIDUPDATE__XSite_1500x600_PV_en-GB._CB428684220_.jpg"
                    alt=""
                />
                <div className='home__row'>
                    <Product
                        id="12321341"
                        title="Pork Cake"
                        price={6.00}
                        rating={5}
                        image="/pork_cake.png"
                    />
                    <Product
                        id="49538094"
                        title="Fried Stuffed Sambo"
                        price={9.00}
                        rating={4}
                        image="/fried_stuffed_sambo.png"
                    />
                    <Product
                        id="90829332"
                        title="Steamed Roll"
                        price={15.00}
                        rating={4}
                        image="/steamed_roll.png"
                    />
                </div>

                <div className="home__row">
                    <Product
                        id="4903850"
                        title="Fishball"
                        price={12.00}
                        rating={8}
                        image="/fishball.png"
                    />
                    <Product
                        id="23445930"
                        title="Egg Waffle"
                        price={13.00}
                        rating={10}
                        image="/egg_waffle.png"
                    />
                    <Product
                        id="3254354345"
                        title="Egg Tart"
                        price={12.00}
                        rating={4}
                        image="egg_tart.png"
                    />
                </div>
            </div>
        </div>
    )
}

export default Home