import React from 'react'
import './Header.css'
import SearchIcon from '@material-ui/icons/Search';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { Link } from 'react-router-dom'
import { useStateValue } from './StateProvider'
import {auth} from './firebase'


function Header() {

    const [{ basket, user}, dispatch] = useStateValue()
    
    var email = ''
    var name =''
    if(user){
         email = user.email
         name   = email.substring(0, email.lastIndexOf("@"))
    }

    const handleAuthentication = ()=>{
        if(user){
            auth.signOut()
        }
    }
    return (
        <div className='header'>
            <Link to="/">
                <img className='header__logo'
                    src="./octopus.png" />
            </Link>

            <div className="header__search">
                <input className="header__searchInput" type="text" />
                <SearchIcon
                    className="header__searchIcon" />
            </div>
            <div className="header__nav">
                <Link to={!user && '/login'}>
                    <div onClick={handleAuthentication} className="header__option">
                        <span className="header__optionLineOne">{user? `Hello ${name}` : 'Hello Guest'}</span>
                        <span className="header__optionLineTwo">{user? 'Sign out' : 'Sign In'}</span>
                    </div>
                </Link>
                <Link to='/orders'>
                    <div className="header__option">
                        <span className="header__optionLineOne">Returns</span>
                        <span className="header__optionLineTwo">Order</span>
                    </div>
                </Link>
                <div className="header__option">
                    <span className="header__optionLineOne">Your</span>
                    <span className="header__optionLineTwo">Prime</span>
                </div>
                <Link to='/checkout'>
                    <div className="header__optionBasket">
                        <ShoppingBasketIcon />
                        <span className="header__optionLineTwo header__basketCount">{basket?.length}</span>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Header